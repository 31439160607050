import classes from "./AppTitle.module.less";
import type { BaseComponentProps } from "JS/React/StyleHelper";
import { makeStyles } from "JS/React/StyleHelper";
import { Typography } from "antd";
import type { TitleProps } from "antd/lib/typography/Title";
import clsx from "clsx";
import React from "react";

type AppTitlePropsClassKeys =
    | "root"
    | "heading"
    | "heading1"
    | "heading2"
    | "heading3"
    | "tertiaryHeading";

export interface AppTitleProps
    extends TitleProps,
        BaseComponentProps<AppTitlePropsClassKeys> {
    titleVarient?: "tertiaryHeading";
}

const useStyles = makeStyles<AppTitlePropsClassKeys>(classes);

export const AppTitle = (props: AppTitleProps) => {
    const classes = useStyles(props);
    const { className, titleVarient, ...rest } = props;

    const { Title } = Typography;
    return (
        <Title
            className={clsx(className, classes.root, classes.heading, {
                [classes.heading1]: rest.level === 1 || !rest.level,
                [classes.heading2]: rest.level === 2,
                [classes.heading3]: rest.level === 3,
                [classes.tertiaryHeading]: titleVarient === "tertiaryHeading",
            })}
            {...rest}
        />
    );
};
