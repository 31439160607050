import classes from "./CarouselCardSkeletion.module.less";
import type { BaseComponentProps } from "JS/React/StyleHelper";
import { makeStyles } from "JS/React/StyleHelper";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

type CarouselCardSkeletonClassKeys = "root" | "arrow" | "content";

export interface CarouselCardSkeletonProps
    extends BaseComponentProps<CarouselCardSkeletonClassKeys> {
    children: React.ReactNode;
}

const useStyles = makeStyles<CarouselCardSkeletonClassKeys>(classes);

export const CarouselCardSkeleton = (props: CarouselCardSkeletonProps) => {
    const { children, ...rest } = props;
    const classes = useStyles(props);
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1547,
                settings: {
                    slidesToShow: 5,
                },
            },
            {
                breakpoint: 1208,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 917,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    return (
        <div style={{ margin: "0px -10px" }}>
            <Slider {...settings} className={classes.content}>
                {children}
            </Slider>
        </div>
    );
};
