import classes from "./AppText.module.less";
import type { BaseComponentProps } from "JS/React/StyleHelper";
import { makeStyles } from "JS/React/StyleHelper";
import { Typography } from "antd";
import type { TextProps } from "antd/lib/typography/Text";
import clsx from "clsx";
import React from "react";

type AppTextPropsClassKeys =
    | "title"
    | "link"
    | "root"
    | "content"
    | "body"
    | "table"
    | "drawerSubtitles"
    | "drawerContent"
    | "error"
    | "score"
    | "filter"
    | "askReportConfirmation"
    | "description"
    | "tableHeader";

export interface AppTextProps
    extends TextProps,
        BaseComponentProps<AppTextPropsClassKeys> {
    textVariant:
        | "title"
        | "link"
        | "content"
        | "body"
        | "table"
        | "tableHeader"
        | "drawerContent"
        | "error"
        | "score"
        | "filter"
        | "askReportConfirmation"
        | "description"
        | "drawerSubtitle";
}

const useStyles = makeStyles<AppTextPropsClassKeys>(classes);

export const AppText = (props: AppTextProps) => {
    const classes = useStyles(props);
    const { className, textVariant, ...rest } = props;

    const { Text } = Typography;
    return (
        <Text
            className={clsx(className, classes.root, {
                [classes.title]: textVariant === "title",
                [classes.link]: textVariant === "link",
                [classes.content]: textVariant === "content",
                [classes.body]: textVariant === "body",
                [classes.tableHeader]: textVariant === "tableHeader",
                [classes.table]: textVariant === "table",
                [classes.drawerSubtitles]: textVariant === "drawerSubtitle",
                [classes.filter]: textVariant === "filter",
                [classes.drawerContent]: textVariant === "drawerContent",
                [classes.error]: textVariant === "error",
                [classes.score]: textVariant === "score",
                [classes.description]: textVariant === "description",
                [classes.askReportConfirmation]:
                    textVariant === "askReportConfirmation",
            })}
            {...rest}
        />
    );
};
