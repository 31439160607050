import classes from "./InfoCircle.module.less";
import { InfoCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import type { BaseComponentProps } from "JS/React/StyleHelper";
import { makeStyles } from "JS/React/StyleHelper";
import clsx from "clsx";
import React from "react";

type InfoCircleClassKeys = "exclamation" | "root" | "question";

export interface InfoCircleProps
    extends BaseComponentProps<InfoCircleClassKeys> {
    style?: React.CSSProperties;
    variant?: "question" | "exclamation";
}

const useStyles = makeStyles<InfoCircleClassKeys>(classes);
export const InfoCircleIcon = (props: InfoCircleProps) => {
    const classes = useStyles(props);
    const { classes: tmp, variant = "exclamation", className, ...rest } = props;
    return (
        <>
            {variant === "exclamation" && (
                <InfoCircleOutlined
                    className={clsx(
                        className,
                        classes.root,
                        classes.exclamation,
                        {}
                    )}
                    {...rest}
                />
            )}
            {variant === "question" && (
                <QuestionCircleOutlined
                    className={clsx(
                        className,
                        classes.root,
                        classes.question,
                        {}
                    )}
                    {...rest}
                />
            )}
        </>
    );
};
