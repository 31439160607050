import "./Styles/App.less";
import reportWebVitals from "./reportWebVitals";
import { App } from "JS/React/Scenes/Root";
import { overwrite } from "country-list";
import React from "react";
// import { createRoot } from "react-dom/client";
import ReactDOM from "react-dom";

overwrite([
    {
        code: "US",
        name: "United States",
    },
]);
// const root = createRoot(document.getElementById("root"));
// root.render(<App />);
ReactDOM.render(<App />, document.getElementById("root"));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
