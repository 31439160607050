import classes from "./CreateReportSkeleton.module.less";
import { Skeletons } from "JS/React/Components/Skeleton/Skeletons";
import type { BaseComponentProps } from "JS/React/StyleHelper";
import { makeStyles } from "JS/React/StyleHelper";
import { Col, Row } from "antd";
import React from "react";

type CreateReportSkeletonClassKeys = "steps";

export interface CreateReportSkeletonProps
    extends BaseComponentProps<CreateReportSkeletonClassKeys> {}

export const useStyles = makeStyles<CreateReportSkeletonClassKeys>(classes);

export const CreateReportSkeleton = (props: CreateReportSkeletonProps) => {
    const classes = useStyles(props);

    return (
        <div>
            <Row>
                <Col span={2}>
                    <Skeletons active variant="navigationInput1" />
                </Col>
                <Col span={20} />
                <Col span={2}>
                    <Skeletons active variant="navigationInput2" />
                </Col>
                <Col span={22} />
            </Row>

            <Row className={classes.steps}>
                <Col span={1}>
                    <Skeletons active variant="avatar" />
                </Col>
                <Col span={3} style={{ display: "flex", alignItems: "center" }}>
                    <Skeletons active variant="cardInput3" />
                </Col>
                <Col span={6} style={{ display: "flex", alignItems: "center" }}>
                    <div
                        className="cancelBtn"
                        style={{
                            height: "1px",
                            width: "100%",
                            margin: "0px 12px",
                        }}
                    />
                </Col>
                <Col span={1}>
                    <Skeletons active variant="avatar" />
                </Col>
                <Col span={3} style={{ display: "flex", alignItems: "center" }}>
                    <Skeletons active variant="cardInput3" />
                </Col>
                <Col span={6} style={{ display: "flex", alignItems: "center" }}>
                    <div
                        className="@lighter-gray"
                        style={{
                            height: "1px",
                            width: "100%",
                            margin: "0px 12px",
                        }}
                    />
                </Col>
                <Col span={1}>
                    <Skeletons active variant="avatar" />
                </Col>
                <Col span={3} style={{ display: "flex", alignItems: "center" }}>
                    <Skeletons active variant="cardInput3" />
                </Col>
            </Row>

            <Row>
                <Col span={4} style={{ marginBottom: "22px" }}>
                    <Skeletons active variant="settingInput1" />
                </Col>
                <Col span={19} />

                <Col span={8}>
                    <Skeletons active variant="drawerInput1" />
                </Col>
                <Col span={13} />
                <Col
                    span={3}
                    style={{ textAlign: "end", marginBottom: "60px" }}
                >
                    <Skeletons active variant="reportNavigationButton" />
                </Col>

                {[1, 2, 3].map((x) => (
                    <>
                        <Col span={10} style={{ marginBottom: "70px" }}>
                            <Skeletons active variant="drawerInput2" />
                        </Col>
                        <Col span={14} />
                    </>
                ))}
            </Row>
        </div>
    );
};
