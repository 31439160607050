import { OrganizationCardSkeleton } from "JS/React/Components/Cards/OrganizationCardSkeleton";
import classes from "./OrganizationsSkeleton.module.less";
import { Skeletons } from "JS/React/Components/Skeleton/Skeletons";
import type { BaseComponentProps } from "JS/React/StyleHelper";
import { makeStyles } from "JS/React/StyleHelper";
import { Col, Row } from "antd";
import React from "react";

type OrganizationsSkeletonClassKeys =
    | "title"
    | "flexClass"
    | "filter"
    | "cardContainer";

export interface OrganizationsSkeletonProps
    extends BaseComponentProps<OrganizationsSkeletonClassKeys> {}
const useStyles = makeStyles<OrganizationsSkeletonClassKeys>(classes);

function OrganizationsSkeleton(props: OrganizationsSkeletonProps) {
    const classes = useStyles(props);

    return (
        <>
            <Row>
                <Row className={classes.flexClass}>
                    <Col className={classes.title} span={6}>
                        <Skeletons variant="navigationInput1" active />
                    </Col>
                    <Col
                        span={4}
                        offset={14}
                        style={{
                            textAlign: "right",
                        }}
                    >
                        <Skeletons variant="navigationButton" active />
                    </Col>
                </Row>
            </Row>

            <Row className={classes.filter} gutter={16}>
                <Col span={6}>
                    <Skeletons variant="navigationInput1" active />
                </Col>
                <Col span={4}>
                    <Skeletons variant="navigationInput1" active />
                </Col>
                <Col span={4}>
                    <Skeletons variant="navigationInput1" active />
                </Col>
                <Col span={7} offset={3}>
                    <Skeletons variant="navigationInput1" active />
                </Col>
            </Row>

            <Row className={classes.cardContainer} gutter={32}>
                {[1, 2, 3, 4, 5, 6].map((client) => (
                    <Col span={24} xl={12} key={client}>
                        <OrganizationCardSkeleton />
                    </Col>
                ))}
            </Row>
        </>
    );
}

export default OrganizationsSkeleton;
