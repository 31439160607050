import classes from "./DisplayReportsSkeleton.module.less";
import { ReportChartSkeleton } from "JS/React/Scenes/Reports/Components/DisplayReports/Charts/Line/ReportChartSkeleton";
import { CarouselInfoSkeleton } from "JS/React/Scenes/Reports/Components/DisplayReports/Components/CarouselInfoSkeleton";
import { ReportNavigationSkeleton } from "JS/React/Scenes/Reports/Components/ReportNavigationSkeleton";
import type { BaseComponentProps } from "JS/React/StyleHelper";
import { makeStyles } from "JS/React/StyleHelper";
import { Col, Row } from "antd";
import React from "react";

type DisplayReportsSkeletonClassKeys = "root" | "spacing";

export interface DisplayReportsSkeletonProps
    extends BaseComponentProps<DisplayReportsSkeletonClassKeys> {}

const useStyles = makeStyles<DisplayReportsSkeletonClassKeys>(classes);
export const DisplayReportsSkeleton = (props: DisplayReportsSkeletonProps) => {
    const classes = useStyles(props);
    return (
        <>
            <ReportNavigationSkeleton />
            <div className={classes.root}>
                <CarouselInfoSkeleton />
                <Row>
                    <Col span={24} className={classes.spacing}>
                        <ReportChartSkeleton />
                    </Col>
                    {/* <Col span={24} className={classes.spacing}>
                        <ReportTableSkeleton />
                    </Col> */}
                </Row>
            </div>
        </>
    );
};
