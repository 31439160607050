import classes from "./AnalyticReportsSkeleton.module.less";
import { Skeletons } from "JS/React/Components/Skeleton/Skeletons";
import { ReportCardSkeleton } from "JS/React/Scenes/Reports/ReportCardSkeleton";
import type { BaseComponentProps } from "JS/React/StyleHelper";
import { makeStyles } from "JS/React/StyleHelper";
import { Col, Row } from "antd";
import React from "react";

type AnalyticReportsSkeletonClassKeys = "spacing" | "flexClass" | "filtersRow";

export interface AnalyticReportsSkeletonProps
    extends BaseComponentProps<AnalyticReportsSkeletonClassKeys> {}

export const useStyles = makeStyles<AnalyticReportsSkeletonClassKeys>(classes);

export const AnalyticReportsSkeleton = (
    props: AnalyticReportsSkeletonProps
) => {
    const classes = useStyles(props);

    return (
        <>
            <Row>
                <Col span={4}>
                    <Skeletons active variant="drawerInput1" />
                </Col>
                <Col span={18} />
                <Col span={2} style={{ marginLeft: "-14px" }}>
                    <Skeletons active variant="navigationButton" />
                </Col>
            </Row>

            <Row style={{ margin: "30px 0px 40px" }}>
                <Col span={2}>
                    <Skeletons active variant="navigationInput1" />
                </Col>
                <Col span={2} style={{ margin: "0px 10px" }}>
                    <Skeletons active variant="navigationInput1" />
                </Col>
                <Col span={2}>
                    <Skeletons active variant="navigationInput1" />
                </Col>
            </Row>

            <Row className={classes.filtersRow}>
                <Col span={5}>
                    <Skeletons active variant="drawerInput1" />
                </Col>
                <Col span={3} style={{ padding: "0px 16px" }}>
                    <Skeletons active variant="drawerInput1" />
                </Col>
                <Col span={2}>
                    <Skeletons active variant="drawerInput1" />
                </Col>
                <Col span={8} />
                <Col span={6}>
                    <Skeletons active variant="drawerInput1" />
                </Col>
            </Row>
            <Row className={classes.filtersRow} gutter={[25, 0]}>
                {[1, 2, 3, 4, 5, 6].map((brand) => (
                    <Col span={24} xl={8} lg={12} key={brand}>
                        <ReportCardSkeleton />
                    </Col>
                ))}
            </Row>
        </>
    );
};
