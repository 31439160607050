import classes from "./Skeletons.module.less";
import type { BaseComponentProps } from "JS/React/StyleHelper";
import { makeStyles } from "JS/React/StyleHelper";
import type { SkeletonProps } from "antd";
import { Skeleton } from "antd";
import React from "react";

type SkeletonsClassKeys =
    | "spacing"
    | "cardButton"
    | "circleButton"
    | "circleButton1"
    | "connectionButton"
    | "cardInput1"
    | "cardInput2"
    | "cardInput3"
    | "cardInput4"
    | "cardInput5"
    | "card"
    | "table1"
    | "table2"
    | "avatar"
    | "pagination"
    | "navigationInput1"
    | "largeInput"
    | "navigationInput2"
    | "navigationInput3"
    | "navigationButton"
    | "reportNavigationButton"
    | "settingInput1"
    | "settingInput2"
    | "scoreCardInput1"
    | "scoreCardInput2"
    | "reportTableInput"
    | "drawerInput1"
    | "drawerInput2"
    | "createButton"
    | "drawerTitle";

export interface SkeletonsProps
    extends SkeletonProps,
        BaseComponentProps<SkeletonsClassKeys> {
    variant:
        | "cardButton"
        | "circleButton"
        | "circleButton1"
        | "connectionButton"
        | "createButton"
        | "cardInput1"
        | "cardInput2"
        | "cardInput3"
        | "cardInput4"
        | "cardInput5"
        | "card"
        | "table1"
        | "table2"
        | "avatar"
        | "pagination"
        | "navigationInput1"
        | "largeInput"
        | "navigationInput2"
        | "navigationInput3"
        | "navigationButton"
        | "reportNavigationButton"
        | "settingInput1"
        | "settingInput2"
        | "scoreCardInput1"
        | "scoreCardInput2"
        | "reportTableInput"
        | "drawerInput1"
        | "drawerInput2"
        | "drawerTitle";

    size?: "small" | "large" | "default";
}

export const useStyles = makeStyles<SkeletonsClassKeys>(classes);

export const Skeletons = (props: SkeletonsProps) => {
    const classes = useStyles(props);
    const { variant, size = "default", ...rest } = props;
    return (
        <>
            {variant === "cardButton" && (
                <Skeleton.Button
                    className={classes.cardButton}
                    size={size}
                    {...rest}
                />
            )}
            {variant === "circleButton" && (
                <Skeleton.Button
                    shape="circle"
                    className={classes.circleButton}
                    size={size}
                    {...rest}
                />
            )}
            {variant === "circleButton1" && (
                <Skeleton.Button
                    shape="circle"
                    className={classes.circleButton1}
                    size={size}
                    {...rest}
                />
            )}
            {variant === "connectionButton" && (
                <Skeleton.Button
                    className={classes.connectionButton}
                    shape="square"
                    size={size}
                    {...rest}
                />
            )}

            {variant === "cardInput1" && (
                <Skeleton.Input
                    className={classes.cardInput1}
                    size={size}
                    {...rest}
                />
            )}
            {variant === "cardInput5" && (
                <Skeleton.Input
                    className={classes.cardInput5}
                    size={size}
                    {...rest}
                />
            )}

            {variant === "cardInput2" && (
                <Skeleton.Input
                    className={classes.cardInput2}
                    size={size}
                    {...rest}
                />
            )}
            {variant === "cardInput3" && (
                <Skeleton.Input
                    className={classes.cardInput3}
                    size={size}
                    {...rest}
                />
            )}
            {variant === "cardInput4" && (
                <Skeleton.Input
                    className={classes.cardInput4}
                    size={size}
                    {...rest}
                />
            )}
            {variant === "card" && (
                <Skeleton.Input
                    className={classes.card}
                    size={size}
                    {...rest}
                />
            )}
            {variant === "table1" && (
                <Skeleton.Input
                    className={classes.table1}
                    size={size}
                    {...rest}
                />
            )}
            {variant === "table2" && (
                <Skeleton.Input
                    className={classes.table2}
                    size={size}
                    {...rest}
                />
            )}
            {variant === "navigationInput1" && (
                <Skeleton.Input
                    className={classes.navigationInput1}
                    size={size}
                    {...rest}
                />
            )}
            {variant === "largeInput" && (
                <Skeleton.Input
                    className={classes.largeInput}
                    size={size}
                    {...rest}
                />
            )}
            {variant === "createButton" && (
                <Skeleton.Input
                    className={classes.createButton}
                    size={size}
                    {...rest}
                />
            )}
            {variant === "pagination" && (
                <Skeleton.Input
                    className={classes.pagination}
                    size={size}
                    {...rest}
                />
            )}
            {variant === "navigationInput2" && (
                <Skeleton.Input
                    className={classes.navigationInput2}
                    size={size}
                    {...rest}
                />
            )}
            {variant === "navigationInput3" && (
                <Skeleton.Input
                    className={classes.navigationInput3}
                    size={size}
                    {...rest}
                />
            )}
            {variant === "navigationButton" && (
                <Skeleton.Button
                    className={classes.navigationButton}
                    size={size}
                    {...rest}
                />
            )}
            {variant === "reportNavigationButton" && (
                <Skeleton.Button
                    className={classes.reportNavigationButton}
                    size={size}
                    {...rest}
                />
            )}
            {variant === "settingInput1" && (
                <Skeleton.Input
                    className={classes.settingInput1}
                    size={size}
                    {...rest}
                />
            )}
            {variant === "settingInput2" && (
                <Skeleton.Input
                    className={classes.settingInput2}
                    size={size}
                    {...rest}
                />
            )}
            {variant === "scoreCardInput1" && (
                <Skeleton.Input
                    className={classes.scoreCardInput1}
                    size={size}
                    {...rest}
                />
            )}
            {variant === "scoreCardInput2" && (
                <Skeleton.Input
                    className={classes.scoreCardInput2}
                    size={size}
                    {...rest}
                />
            )}
            {variant === "reportTableInput" && (
                <Skeleton.Input
                    className={classes.reportTableInput}
                    size={size}
                    {...rest}
                />
            )}
            {variant === "drawerInput1" && (
                <Skeleton.Input
                    className={classes.drawerInput1}
                    size={size}
                    {...rest}
                />
            )}
            {variant === "drawerInput2" && (
                <Skeleton.Input
                    className={classes.drawerInput2}
                    size={size}
                    {...rest}
                />
            )}
            {variant === "drawerTitle" && (
                <Skeleton.Input
                    className={classes.drawerTitle}
                    size={size}
                    {...rest}
                />
            )}
            {variant === "avatar" && (
                <Skeleton.Avatar
                    className={classes.avatar}
                    size={size}
                    {...rest}
                />
            )}
        </>
    );
};
