import classes from "./ReportChartSkeleton.module.less";
import BarChartSkeleton from "Images/ReportSkeletons/BarChartSkeleton.svg";
import DonutSkeleton from "Images/ReportSkeletons/DonutSkeleton.svg";
import LineChartSkeleton from "Images/ReportSkeletons/LineChartSkeleton.svg";
import { ChartContainer } from "JS/React/Components/ChartContainer";
import type { BaseComponentProps } from "JS/React/StyleHelper";
import { makeStyles } from "JS/React/StyleHelper";
import React from "react";

type ReportChartSkeletonClassKeys = "root" | "chartContainer";

export interface ReportChartSkeletonProps
    extends BaseComponentProps<ReportChartSkeletonClassKeys> {
    variant?: "line" | "donut" | "bar";
}

const useStyles = makeStyles<ReportChartSkeletonClassKeys>(classes);

export const ReportChartSkeleton = (props: ReportChartSkeletonProps) => {
    const classes = useStyles(props);
    const { classes: tmp, className, variant = "line", ...rest } = props;

    return (
        <ChartContainer style={{ height: 500 }}>
            <div className={classes.chartContainer} style={{ height: "100%" }}>
                <img
                    alt="chart"
                    height={"100%"}
                    width={"100%"}
                    src={
                        variant === "bar"
                            ? BarChartSkeleton
                            : variant === "donut"
                            ? DonutSkeleton
                            : LineChartSkeleton
                    }
                />
            </div>
        </ChartContainer>
    );
};
