import classes from "./UsersSkeleton.module.less";
import { Skeletons } from "JS/React/Components/Skeleton/Skeletons";
import GeneralTableSkeleton from "JS/React/Scenes/Integrations/GeneralTableSkeleton";
import UserTableSkeleton from "JS/React/Scenes/Users/UserTableSkeleton";
import type { BaseComponentProps } from "JS/React/StyleHelper";
import { makeStyles } from "JS/React/StyleHelper";
import { Col, Row } from "antd";
import React from "react";

type UsersSkeletonClassKeys =
    | "title"
    | "flexClass"
    | "filter"
    | "cardContainer";

export interface UsersSkeletonProps
    extends BaseComponentProps<UsersSkeletonClassKeys> {}
const useStyles = makeStyles<UsersSkeletonClassKeys>(classes);

function UsersSkeleton(props: UsersSkeletonProps) {
    const classes = useStyles(props);
    return (
        <>
            <Row>
                <Row className={classes.flexClass}>
                    <Col className={classes.title} span={6}>
                        <Skeletons variant="navigationInput1" active />
                    </Col>
                    <Col
                        span={4}
                        offset={14}
                        style={{
                            textAlign: "right",
                        }}
                    >
                        <Skeletons variant="navigationButton" active />
                    </Col>
                </Row>
            </Row>

            <Row className={classes.filter} gutter={16}>
                <Col span={6}>
                    <Skeletons variant="navigationInput1" active />
                </Col>
                <Col span={4}>
                    <Skeletons variant="navigationInput1" active />
                </Col>
                <Col span={4}>
                    <Skeletons variant="navigationInput1" active />
                </Col>
                <Col span={7} offset={3}>
                    <Skeletons variant="navigationInput1" active />
                </Col>
            </Row>

            <Row className={classes.cardContainer} gutter={32}>
                <Col span={24}>
                    <GeneralTableSkeleton />
                </Col>
            </Row>
        </>
    );
}

export default UsersSkeleton;
