import React, { createContext, useContext, useState } from "react";

export interface SkeletonContextType {
    showSkeleton: boolean;
    updateShowSkeleton: (val: boolean) => void;
}

export const SkeletonContext = createContext<SkeletonContextType>({
    showSkeleton: true,
    updateShowSkeleton: () => {},
});
export const useSkeletonContext = () => useContext(SkeletonContext);

export interface SkeletonProviderProps {
    showSkeleton: boolean;
    children?: () => React.ReactNode;
    renderSkeleton: () => React.ReactNode;
}

export const SkeletonProvider = (props: SkeletonProviderProps) => {
    const [showSkeleton, setState] = useState<
        SkeletonContextType["showSkeleton"]
    >(props.showSkeleton);

    return (
        <SkeletonContext.Provider
            value={{
                showSkeleton: showSkeleton,
                updateShowSkeleton: setState,
            }}
        >
            <div style={{ width: "100%" }}>
                {props.showSkeleton ? props.renderSkeleton() : props.children()}
            </div>
        </SkeletonContext.Provider>
    );
};
