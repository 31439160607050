import { SkeletonProvider } from "JS/React/Container/Skeleton";
import AppLayoutSkeleton from "JS/React/Scenes/AppLayoutSkeleton";
import LoginLayoutSkeleton from "JS/React/Scenes/Login/LoginLayoutSkeleton";
import React, { Suspense } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";

const SkeletonRoot = () => {
    const location = useLocation();
    const pathname = ["/login", "/invite"];
    const checkPath = () => {
        let flag = false;
        pathname.forEach((x) => {
            if (location.pathname.includes(x)) {
                flag = true;
            }
        });
        return flag;
    };
    return (
        <SkeletonProvider
            showSkeleton
            renderSkeleton={() => {
                return checkPath() ? (
                    <LoginLayoutSkeleton />
                ) : (
                    <AppLayoutSkeleton />
                );
            }}
        />
    );
};

const Root = React.lazy(async () => {
    return import("./Main");
});

export const App = (props) => {
    return (
        <div>
            <Suspense
                fallback={
                    <Router>
                        <SkeletonRoot />
                    </Router>
                }
            >
                <Root />
            </Suspense>
        </div>
    );
};
