import classes from "./AppSidebarSkeleton.module.less";
import { Skeletons } from "JS/React/Components/Skeleton/Skeletons";
import type { BaseComponentProps } from "JS/React/StyleHelper";
import { makeStyles } from "JS/React/StyleHelper";
import { Col, Layout, Row } from "antd";
import type { SiderProps } from "antd/lib/layout";
import clsx from "clsx";
import React from "react";

type AppSidebarSkeletonPropsClassKeys = "root" | "sider";

export interface AppSidebarSkeletonProps
    extends SiderProps,
        BaseComponentProps<AppSidebarSkeletonPropsClassKeys> {}

export interface SidebarItemModel {}

const { Sider } = Layout;
const useStyles = makeStyles<AppSidebarSkeletonPropsClassKeys>(classes);
export const AppSidebarSkeleton = (props: AppSidebarSkeletonProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;

    return (
        <Sider
            width={260}
            className={clsx(classes.root, classes.sider)}
            {...rest}
        >
            <div
                className={clsx(classes.root, classes.sider)}
                style={{ borderRight: 0 }}
            >
                {[1, 2, 3, 4].map((e) => {
                    return (
                        <Row
                            style={{ width: "100%", margin: "35px 12px" }}
                            key={e}
                        >
                            <Col
                                span={24}
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <div style={{ width: "17%" }}>
                                    <Skeletons variant="avatar" active />
                                </div>
                                <div style={{ width: "70%" }}>
                                    <Skeletons variant="drawerInput1" active />
                                </div>
                            </Col>
                        </Row>
                    );
                })}
            </div>
        </Sider>
    );
};
