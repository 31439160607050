import classes from "./InviteUserSkeleton.module.less";
import { Skeletons } from "JS/React/Components/Skeleton/Skeletons";
import type { BaseComponentProps } from "JS/React/StyleHelper";
import { makeStyles } from "JS/React/StyleHelper";
import { Col, Row } from "antd";
import React from "react";

type InviteUserSkeletonClasses = "spacing";
const useStyles = makeStyles<InviteUserSkeletonClasses>(classes);

export interface InviteUserSkeletonProps
    extends BaseComponentProps<InviteUserSkeletonClasses> {}

function InviteUserSkeleton(props: InviteUserSkeletonProps) {
    const classes = useStyles(props);
    const { className, ...rest } = props;

    return (
        <Row>
            <Col span={24} className={classes.spacing}>
                <Row justify="space-between">
                    <Col span={8}>
                        <Skeletons active variant="drawerInput2" />
                    </Col>
                    <Col span={8} offset={8}>
                        <Skeletons active variant="drawerInput2" />
                    </Col>
                </Row>
            </Col>
            <Col span={24} className={classes.spacing}>
                <Skeletons active variant="drawerInput2" />
            </Col>

            <Col span={24} className={classes.spacing}>
                <Skeletons active variant="drawerInput2" />
            </Col>
            <Col span={24} className={classes.spacing}>
                <Skeletons active variant="drawerInput2" />
            </Col>
            <Col span={24} className={classes.spacing}>
                <Skeletons active variant="drawerInput2" />
            </Col>

            <Col
                span={24}
                className={classes.spacing}
                style={{ justifyContent: "center", display: "flex" }}
            >
                <Skeletons active variant="navigationButton" />
            </Col>
        </Row>
    );
}

export default InviteUserSkeleton;
