import classes from "./ChartContainer.module.less";
import { AppTitle } from "JS/React/Components/AppTypography/AppTitle";
import { InfoCircleIcon } from "JS/React/Components/Icons/InfoCircle";
import type { BaseComponentProps } from "JS/React/StyleHelper";
import { makeStyles } from "JS/React/StyleHelper";
import { Card, Spin, Tooltip } from "antd";
import clsx from "clsx";
import type { ReactNode } from "react";
import React, { useState } from "react";

type ChartContainerClassKeys =
    | "root"
    | "card"
    | "body"
    | "tooltip"
    | "title"
    | "toolbar"
    | "contentContainer"
    | "cardTitle";

export interface RenderProps {
    contentHeight: number;
}

export interface ChartContainerProps
    extends BaseComponentProps<ChartContainerClassKeys> {
    title?: string;
    helpTooltip?: string;
    loading?: boolean;
    status?: boolean;
    renderRightToolbar?: () => ReactNode;
    onRefetch?: () => void;
    children?: ReactNode | ((renderProps: RenderProps) => ReactNode);
    style?: React.CSSProperties;
}

const useStyles = makeStyles<ChartContainerClassKeys>(classes);

export const ChartContainer = (props: ChartContainerProps) => {
    const classes = useStyles(props);
    const {
        classes: tmp,
        className,
        title,
        helpTooltip,
        loading,
        status,
        renderRightToolbar,
        onRefetch,
        children,
        style,
        ...rest
    } = props;
    const [contentHeight, setContentHeight] = useState(500);

    return (
        <Card
            className={clsx(classes.root, classes.card)}
            style={style}
            bodyStyle={{ height: "100%", width: "100%" }}
            {...rest}
        >
            <div className={classes.body}>
                <div className={classes.cardTitle}>
                    <div>
                        {title && (
                            <AppTitle className={classes.title} level={3}>
                                {title}
                            </AppTitle>
                        )}{" "}
                    </div>
                    <div className={classes.toolbar}>
                        {!loading && renderRightToolbar && renderRightToolbar()}
                        <>
                            {helpTooltip && (
                                <Tooltip
                                    title={helpTooltip}
                                    placement="topLeft"
                                    className={classes.tooltip}
                                    arrowPointAtCenter
                                >
                                    <InfoCircleIcon variant="question" />
                                </Tooltip>
                            )}
                        </>
                    </div>
                </div>

                {loading && (
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        <Spin tip="Loading..." />
                    </div>
                )}
                <div
                    className={classes.contentContainer}
                    ref={(e) => {
                        if (e) {
                            setContentHeight(e.clientHeight);
                        }
                    }}
                >
                    {!loading &&
                        (typeof children === "function"
                            ? children({
                                  contentHeight: contentHeight,
                              })
                            : children)}
                </div>
            </div>
        </Card>
    );
};
