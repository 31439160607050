import classes from "./ReportCardSkeleton.module.less";
import { Skeletons } from "JS/React/Components/Skeleton/Skeletons";
import type { BaseComponentProps } from "JS/React/StyleHelper";
import { makeStyles } from "JS/React/StyleHelper";
import type { CardProps } from "antd";
import { Card, Col, Row } from "antd";
import clsx from "clsx";
import React from "react";

type ReportCardSkeletonClassKeys = "root" | "card" | "cardTitle";

export interface ReportCardSkeletonProps
    extends CardProps,
        BaseComponentProps<ReportCardSkeletonClassKeys> {}
const useStyles = makeStyles<ReportCardSkeletonClassKeys>(classes);

export const ReportCardSkeleton = (props: ReportCardSkeletonProps) => {
    const classes = useStyles(props);
    const { classes: tmp, className, ...rest } = props;
    return (
        <Card bordered={false} className={clsx(classes.root, classes.card)}>
            <Row className={classes.cardTitle} justify="space-between">
                <Col span={16}>
                    <Skeletons variant="cardInput2" active />
                </Col>
                <Col span={8} />
                <Col span={9} style={{ marginTop: "20px" }}>
                    <Skeletons variant="cardInput2" active />
                </Col>
                <Col span={9} />
                <Col span={6} style={{ marginTop: "20px" }}>
                    <Skeletons variant="cardInput2" active />
                </Col>
            </Row>
        </Card>
    );
};
