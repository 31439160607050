import classes from "./GeneralTableSkeleton.module.less";
import { Skeletons } from "JS/React/Components/Skeleton/Skeletons";
import type { BaseComponentProps } from "JS/React/StyleHelper";
import { makeStyles } from "JS/React/StyleHelper";
import { Col, Row } from "antd";
import React from "react";

type GeneralTableSkeletonClassKeys =
    | "spacing"
    | "back"
    | "titleDiv"
    | "row"
    | "leftTitle"
    | "createIntegration"
    | "alignCenter"
    | "rightContainer"
    | "leftContainer";

export interface GeneralTableSkeletonProps
    extends BaseComponentProps<GeneralTableSkeletonClassKeys> {}

export const useStyles = makeStyles<GeneralTableSkeletonClassKeys>(classes);

export const GeneralTableSkeleton = (props: GeneralTableSkeletonProps) => {
    const classes = useStyles(props);

    return (
        <div style={{ width: "100%" }}>
            {[...Array(6)].map((_, index) => (
                <Row
                    className="rowBorderLightGray"
                    style={{
                        marginBottom: "33px",
                        marginTop: "33px",
                        justifyContent: "space-between", // Add bottom border
                        paddingBottom: "10px", // Add padding to create spacing between rows
                    }}
                    key={index}
                >
                    <Col span={4}>
                        <Skeletons variant="navigationInput1" active />
                    </Col>
                    <Col span={4}>
                        <Skeletons variant="navigationInput1" active />
                    </Col>
                    <Col span={4}>
                        <Skeletons variant="navigationInput1" active />
                    </Col>
                    <Col span={4}>
                        <Skeletons variant="navigationInput1" active />
                    </Col>
                    <Col span={4}>
                        <Skeletons variant="navigationInput1" active />
                    </Col>
                    <Col
                        span={2}
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",

                            marginBottom: "44px",
                        }}
                    >
                        <Skeletons variant="connectionButton" active />
                        <Skeletons
                            style={{ marginLeft: "15px" }}
                            variant="connectionButton"
                            active
                        />
                    </Col>
                </Row>
            ))}
        </div>
    );
};

export default GeneralTableSkeleton;
