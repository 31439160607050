import classes from "./CreateIntegrationSkeleton.module.less";
import { Skeletons } from "JS/React/Components/Skeleton/Skeletons";
import type { BaseComponentProps } from "JS/React/StyleHelper";
import { makeStyles } from "JS/React/StyleHelper";
import { Col, Row } from "antd";
import React from "react";

type CreateIntegrationSkeletonClassKeys =
    | "spacing"
    | "back"
    | "titleDiv"
    | "row"
    | "leftTitle"
    | "createIntegration"
    | "alignCenter"
    | "rightContainer"
    | "leftContainer";

export interface CreateIntegrationSkeletonProps
    extends BaseComponentProps<CreateIntegrationSkeletonClassKeys> {}

export const useStyles =
    makeStyles<CreateIntegrationSkeletonClassKeys>(classes);

export const CreateIntegrationSkeleton = (
    props: CreateIntegrationSkeletonProps
) => {
    const classes = useStyles(props);

    return (
        <Row>
            <Col className={classes.back} span={3}>
                <Skeletons active variant="navigationInput1" />
            </Col>
            <Col span={21} />
            <Row className={classes.titleDiv}>
                <Col span={5}>
                    <Skeletons active variant="navigationInput1" />
                </Col>
                <Col>
                    <Skeletons active variant="navigationButton" />
                </Col>
            </Row>
            <Row className={classes.row}>
                <Col span={6} className={classes.leftContainer}>
                    <div className={classes.leftTitle}>
                        <Col span={12}>
                            <Skeletons active variant="navigationInput1" />
                        </Col>
                    </div>
                    <Row className={classes.alignCenter}>
                        {[1, 2, 3, 4, 5, 6].map((tab) => {
                            return (
                                <Col span={20} style={{ marginBottom: "15px" }}>
                                    <Skeletons active variant="settingInput1" />
                                </Col>
                            );
                        })}
                    </Row>
                    <Col span={24} className={classes.createIntegration}>
                        <Skeletons active variant="navigationButton" />
                    </Col>
                </Col>
                <Col span={18} className={classes.rightContainer}>
                    <div style={{ marginBottom: "11px" }}>
                        <Col span={7}>
                            <Skeletons active variant="navigationInput1" />
                        </Col>
                        <Col span={17} />
                    </div>
                    <Row gutter={[10, 0]} className={classes.spacing}>
                        {[1, 2, 3, 4, 5].map((i) => (
                            <Col style={{ width: "20%" }}>
                                <Skeletons active variant="card" />
                            </Col>
                        ))}
                    </Row>

                    <Row>
                        {[1, 2, 3].map((x) => {
                            return (
                                <>
                                    <Col
                                        span={10}
                                        style={{
                                            marginBottom: "30px",
                                        }}
                                    >
                                        <Skeletons
                                            active
                                            variant="drawerInput2"
                                        />
                                    </Col>
                                    <Col
                                        span={10}
                                        offset={4}
                                        style={{
                                            marginBottom: "30px",
                                        }}
                                    >
                                        <Skeletons
                                            active
                                            variant="drawerInput2"
                                        />
                                    </Col>
                                </>
                            );
                        })}
                    </Row>
                </Col>
            </Row>
        </Row>
    );
};
