import classes from "./OrganizationCardSkeleton.module.less";
import { Skeletons } from "JS/React/Components/Skeleton/Skeletons";
import type { BaseComponentProps } from "JS/React/StyleHelper";
import { makeStyles } from "JS/React/StyleHelper";
import type { CardProps } from "antd";
import { Card, Col, Row } from "antd";
import clsx from "clsx";
import React from "react";

type OrganizationCardSkeletonClassKeys =
    | "root"
    | "card"
    | "CardFooter"
    | "cardTitle";

export interface OrganizationCardSkeletonProps
    extends CardProps,
        BaseComponentProps<OrganizationCardSkeletonClassKeys> {
    connection?: boolean;
}
const useStyles = makeStyles<OrganizationCardSkeletonClassKeys>(classes);

export const OrganizationCardSkeleton = (
    props: OrganizationCardSkeletonProps
) => {
    const classes = useStyles(props);

    const { classes: tmp, className, connection = true, ...rest } = props;

    return (
        <Card
            bordered={false}
            onClick={rest.onClick}
            className={clsx(classes.root, classes.card)}
            actions={[
                <Row className={classes.CardFooter} justify="space-between">
                    <Col span={9}>
                        <Skeletons variant="cardInput3" active />
                    </Col>

                    <Col span={7}>
                        <Skeletons variant="cardInput4" active />
                    </Col>
                </Row>,
            ]}
        >
            <Row className={classes.cardTitle} justify="space-between">
                <Col span={16}>
                    <Skeletons variant="cardInput2" active />
                </Col>
                {connection && (
                    <Col
                        span={4}
                        offset={4}
                        flex="end"
                        style={{ textAlign: "end" }}
                    >
                        <Skeletons variant="cardButton" active />
                    </Col>
                )}
                <Col span={9}>
                    <div
                        style={{
                            marginTop: "14px",
                            marginBottom: "9px",
                        }}
                    >
                        <Skeletons variant="cardInput2" active />
                    </div>
                </Col>
            </Row>
        </Card>
    );
};
