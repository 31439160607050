import classes from "./ReportNavigationSkeleton.module.less";
import { Skeletons } from "JS/React/Components/Skeleton/Skeletons";
import type { BaseComponentProps } from "JS/React/StyleHelper";
import { makeStyles } from "JS/React/StyleHelper";
import { Affix, Col, Row } from "antd";
import clsx from "clsx";
import React from "react";

type ReportNavigationSkeletonPropsClassKeys =
    | "root"
    | "affix"
    | "affixContent"
    | "flex";
export interface ReportNavigationSkeletonProps
    extends BaseComponentProps<ReportNavigationSkeletonPropsClassKeys> {}

const useStyles = makeStyles<ReportNavigationSkeletonPropsClassKeys>(classes);

export const ReportNavigationSkeleton = (
    props: ReportNavigationSkeletonProps
) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;

    return (
        <Affix
            offsetTop={0}
            className={clsx(className, classes.root, classes.affix)}
            {...rest}
        >
            <div className={classes.affixContent}>
                <Row>
                    <Col span={2} style={{ marginRight: "10px" }}>
                        <Skeletons active variant="navigationInput2" />
                    </Col>
                    /
                    <Col span={2} style={{ margin: "0px 10px" }}>
                        <Skeletons active variant="navigationInput2" />
                    </Col>
                    /
                    <Col span={2} style={{ marginLeft: "10px" }}>
                        <Skeletons active variant="navigationInput2" />
                    </Col>
                    <Col span={16} />
                    {/* new row */}
                    <Row style={{ width: "100%" }}>
                        <Col span={2} style={{ marginTop: "26px" }}>
                            <Skeletons active variant="navigationInput2" />
                        </Col>
                        <Col span={22} className={classes.flex}>
                            <Skeletons
                                active
                                variant="reportNavigationButton"
                            />
                            <Skeletons
                                active
                                style={{ marginLeft: "10px" }}
                                variant="reportNavigationButton"
                            />
                        </Col>
                    </Row>
                    {/* new row */}
                    <Col span={7} style={{ marginTop: "10px" }}>
                        <Skeletons active variant="navigationInput2" />
                    </Col>
                    <Col span={17} />
                    {/* new row */}
                    <Col span={2} style={{ marginTop: "10px" }}>
                        <Skeletons active variant="navigationInput2" />
                    </Col>
                    <Col
                        span={2}
                        style={{ marginTop: "10px", marginLeft: "10px" }}
                    >
                        <Skeletons active variant="navigationInput2" />
                    </Col>
                    <Col span={19} />
                    {/* new row */}
                    <Col span={1} style={{ marginTop: "30px" }}>
                        <Skeletons active variant="navigationInput2" />
                    </Col>
                    <Col
                        span={1}
                        style={{ marginTop: "30px", marginLeft: "30px" }}
                    >
                        <Skeletons active variant="navigationInput2" />
                    </Col>
                    <Col
                        span={1}
                        style={{ marginTop: "30px", marginLeft: "30px" }}
                    >
                        <Skeletons active variant="navigationInput2" />
                    </Col>
                    <Col span={21} />
                </Row>
            </div>
        </Affix>
    );
};
