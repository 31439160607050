import classes from "./ScoreCardSkeleton.module.less";
import { Skeletons } from "JS/React/Components/Skeleton/Skeletons";
import type { BaseComponentProps } from "JS/React/StyleHelper";
import { makeStyles } from "JS/React/StyleHelper";
import { Card, Col, Row } from "antd";
import clsx from "clsx";
import React from "react";

type ScoreCardSkeletonClassKeys = "root" | "card";

export interface ScoreCardSkeletonProps
    extends BaseComponentProps<ScoreCardSkeletonClassKeys> {}

const useStyles = makeStyles<ScoreCardSkeletonClassKeys>(classes);

export const ScoreCardSkeleton = (props: ScoreCardSkeletonProps) => {
    const classes = useStyles(props);
    const { classes: tmp, className, ...rest } = props;

    return (
        <Card
            style={{ borderColor: "rgb(217, 217, 217)" }}
            className={clsx(classes.root, classes.card)}
            bodyStyle={{ height: "100%" }}
        >
            <Row
                style={{
                    width: "100%",
                }}
            >
                <Col span={12}>
                    <Skeletons active variant="scoreCardInput1" />
                </Col>

                <Col span={14}>
                    <Skeletons active variant="scoreCardInput2" />
                </Col>
            </Row>
        </Card>
    );
};
