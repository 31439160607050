import { ScoreCardSkeleton } from "JS/React/Components/Cards/ScoreCardSkeleton";
import classes from "./CarouselInfoSkeleton.module.less";
import { CarouselCardSkeleton } from "JS/React/Components/Cards/CarouselCardSkeletion";
import type { BaseComponentProps } from "JS/React/StyleHelper";
import { makeStyles } from "JS/React/StyleHelper";
import React from "react";

type CarouselInfoSkeletonClassKeys = "titleDiv";

export interface CarouselInfoSkeletonProps
    extends BaseComponentProps<CarouselInfoSkeletonClassKeys> {}

const useStyles = makeStyles<CarouselInfoSkeletonClassKeys>(classes);

export const CarouselInfoSkeleton = (props: CarouselInfoSkeletonProps) => {
    const classes = useStyles(props);
    const { classes: tmp, className, ...rest } = props;

    return (
        <>
            <div className={classes.titleDiv} />
            <div style={{ marginBottom: "30px" }}>
                <CarouselCardSkeleton>
                    {[1, 2, 3, 4, 5].map((c, idx) => (
                        <div>
                            <ScoreCardSkeleton />
                        </div>
                    ))}
                </CarouselCardSkeleton>
            </div>
        </>
    );
};
