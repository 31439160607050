import classes from "./AccountSettingSkeleton.module.less";
import { AppTitle } from "JS/React/Components/AppTypography/AppTitle";
import { Skeletons } from "JS/React/Components/Skeleton/Skeletons";
import type { BaseComponentProps } from "JS/React/StyleHelper";
import { makeStyles } from "JS/React/StyleHelper";
import type { LayoutProps } from "antd";
import { Col, Row } from "antd";
import React from "react";

type AccountSettingClasses = "spacing" | "innerSpacing";
const useStyles = makeStyles<AccountSettingClasses>(classes);

export interface AccountSettingSkeletonProps
    extends LayoutProps,
        BaseComponentProps<AccountSettingClasses> {}
function AccountSettingSkeleton(props: AccountSettingSkeletonProps) {
    const classes = useStyles(props);
    const { className, ...rest } = props;

    return (
        <Row justify="start" style={{ width: "100%" }}>
            <Row className={classes.spacing} style={{ width: "100%" }}>
                <Col span={12}>
                    <AppTitle level={1}>Settings</AppTitle>
                </Col>
            </Row>
            <Row className={classes.innerSpacing} style={{ width: "100%" }}>
                <Col span={6}>
                    <Skeletons variant="settingInput1" active />
                </Col>
            </Row>
            <Row className={classes.spacing} style={{ width: "100%" }}>
                <Col span={12}>
                    <Skeletons variant="settingInput1" active />
                </Col>
            </Row>
            <Row className={classes.innerSpacing} style={{ width: "100%" }}>
                <Col span={6}>
                    <Skeletons variant="settingInput1" active />
                </Col>
            </Row>
            <Row className={classes.spacing} style={{ width: "100%" }}>
                <Col span={12}>
                    <Skeletons variant="settingInput1" active />
                </Col>
            </Row>
            <Row className={classes.innerSpacing} style={{ width: "100%" }}>
                <Col span={6}>
                    <Skeletons variant="settingInput1" active />
                </Col>
            </Row>
            <Row className={classes.spacing} style={{ width: "100%" }}>
                <Col span={12}>
                    <Skeletons variant="settingInput1" active />
                </Col>
            </Row>
            <Row className={classes.innerSpacing} style={{ width: "100%" }}>
                <Col span={6}>
                    <Skeletons variant="settingInput1" active />
                </Col>
            </Row>
            <Row className={classes.spacing} style={{ width: "100%" }}>
                <Col span={12}>
                    <Skeletons variant="settingInput1" active />
                </Col>
            </Row>
            <Row className={classes.innerSpacing} style={{ width: "100%" }}>
                <Col span={6}>
                    <Skeletons variant="settingInput1" active />
                </Col>
            </Row>
            <Row className={classes.spacing} style={{ width: "100%" }}>
                <Col span={12}>
                    <Skeletons variant="settingInput1" active />
                </Col>
            </Row>
            <Row className={classes.innerSpacing} style={{ width: "100%" }}>
                <Col span={6}>
                    <Skeletons variant="settingInput1" active />
                </Col>
            </Row>
            <Row className={classes.spacing} style={{ width: "100%" }}>
                <Col span={12}>
                    <Skeletons variant="settingInput1" active />
                </Col>
            </Row>
            <Row className={classes.innerSpacing} style={{ width: "100%" }}>
                <Col span={6}>
                    <Skeletons variant="settingInput1" active />
                </Col>
            </Row>
            <Row className={classes.spacing} style={{ width: "100%" }}>
                <Col span={12}>
                    <Skeletons variant="settingInput1" active />
                </Col>
            </Row>
        </Row>
    );
}

export default AccountSettingSkeleton;
