import classes from "./LoginSkeleton.module.less";
import { Skeletons } from "JS/React/Components/Skeleton/Skeletons";
import type { BaseComponentProps } from "JS/React/StyleHelper";
import { makeStyles } from "JS/React/StyleHelper";
import { Col, Row } from "antd";
import React from "react";

type LoginSkeletonClasses = "spacing";
const useStyles = makeStyles<LoginSkeletonClasses>(classes);

export interface LoginSkeletonProps
    extends BaseComponentProps<LoginSkeletonClasses> {}

function LoginSkeleton(props: LoginSkeletonProps) {
    const classes = useStyles(props);
    const { className, ...rest } = props;

    return (
        <Row>
            <Col span={24} className={classes.spacing}>
                <Skeletons active variant="drawerInput2" />
            </Col>

            <Col span={24} className={classes.spacing}>
                <Skeletons active variant="drawerInput2" />
            </Col>
            <Col span={24} className={classes.spacing}>
                <Row justify="space-between">
                    <Col span={8}>
                        <Skeletons active variant="navigationInput2" />
                    </Col>
                    <Col span={8} offset={8}>
                        <Skeletons active variant="navigationInput2" />
                    </Col>
                </Row>
            </Col>
            <Col span={24} className={classes.spacing}>
                <Skeletons active variant="navigationButton" />
            </Col>
        </Row>
    );
}

export default LoginSkeleton;
