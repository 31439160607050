import { Skeletons } from "JS/React/Components/Skeleton/Skeletons";
import { Col, Row } from "antd";
import React from "react";

function ClientNavigationSkeleton() {
    return (
        <Row
            justify="space-between"
            style={{ height: "inherit", alignItems: "center" }}
        >
            <Col span={12}>
                <Row justify="start">
                    <Row style={{ width: "100%" }}>
                        <Col span={10}>
                            <Skeletons active variant="navigationInput1" />
                        </Col>
                    </Row>
                    <Col span={14} style={{ margin: "13px 0px" }}>
                        <Skeletons active variant="navigationInput1" />
                    </Col>
                    <Row style={{ width: "100%" }}>
                        <Col span={7}>
                            <Skeletons active variant="navigationInput1" />
                        </Col>
                    </Row>
                </Row>
            </Col>
            <Col span={7}>
                <div style={{ display: "flex", justifyContent: "end" }}>
                    <span style={{ marginRight: "16px" }}>
                        <Skeletons active variant="navigationButton" />
                    </span>
                    <Skeletons active variant="navigationButton" />
                </div>
            </Col>
        </Row>
    );
}

export default ClientNavigationSkeleton;
