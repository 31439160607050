import clsx from "clsx";
import { defaults } from "lodash-es";

export interface BaseComponentProps<T extends string | symbol = never> {
    className?: string;
    classes?: Partial<Record<T, string>>;
}

export const makeStyles =
    <T extends string | symbol = never>(styleClasses: {
        [index: string]: string;
    }) =>
    (props?: BaseComponentProps<T>): Partial<Record<T, string>> => {
        const classes = defaults({}, styleClasses);
        if (props && props.classes) {
            Object.keys(props.classes).forEach((key) => {
                classes[key] = clsx(classes[key], props.classes[key]);
            });
        }
        return classes as Partial<Record<T, string>>;
    };
