import classes from "./LoginLayoutSkeleton.module.less";
import Logo from "Images/nomad.logo.svg";
import NomadText from "Images/newNomad.svg";
import SafeReach from "Images/safeReach.png";
import { AppText } from "JS/React/Components/AppTypography/AppText";
import InviteUserSkeleton from "JS/React/Scenes/Login/InviteUserSkeleton";
import LoginSkeleton from "JS/React/Scenes/Login/LoginSkeleton";
import type { BaseComponentProps } from "JS/React/StyleHelper";
import { makeStyles } from "JS/React/StyleHelper";
import { Col, Row } from "antd";
import clsx from "clsx";
import React from "react";
import { useLocation } from "react-router-dom";

type LoginLayoutClassKeys =
    | "root"
    | "leftContainer"
    | "descDiv"
    | "imagesDiv"
    | "welcome"
    | "container";

export interface LoginLayoutProps
    extends BaseComponentProps<LoginLayoutClassKeys> {
    title?: string;
    children?: React.ReactNode;
}

const useStyles = makeStyles<LoginLayoutClassKeys>(classes);
const LoginLayoutSkeleton = (props: LoginLayoutProps) => {
    const classes = useStyles(props);
    const { classes: tmp, className, children, title, ...rest } = props;
    const location = useLocation();

    return (
        <div>
            <Row style={{ height: "100vh" }}>
                <Col
                    span={12}
                    className={clsx(classes.container, classes.leftContainer)}
                >
                    <div>
                        <img src={Logo} width="250px" height="250px" />
                    </div>
                </Col>
                <Col span={12} className={classes.container}>
                    <Row justify="center" style={{ width: "100%" }}>
                        <Col span={13}>
                            <div>
                                <div className={classes.imagesDiv}>
                                    <img src={NomadText} width="230px" />
                                    {/* <img src={SafeReach} width="150px" /> */}
                                </div>
                                <div className={classes.descDiv}>
                                    <AppText
                                        textVariant="content"
                                        className={classes.welcome}
                                    >
                                        {title}
                                    </AppText>
                                </div>
                                {location.pathname.includes("invite") ? (
                                    <InviteUserSkeleton />
                                ) : (
                                    <LoginSkeleton />
                                )}
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default LoginLayoutSkeleton;
